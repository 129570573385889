<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <MemberTitle :memberTitle="'我的供应商订单'"></MemberTitle>
      <div class="container">
        <h3>我的供应商订单</h3>
        <div class="sld_order_nav">
          <div class="sld_order_nav_con flex_row_start_center pointer">
            <div :class="{ item: true, active: current_state == '' }" @click="changeState('')">
              {{ L["全部订单"] }}
            </div>
            <!-- <div
                :class="{ item: true, active: current_state == 1 }"
                @click="changeState(1)"
                v-if="
                  memberInfo.data.userType == 2 || memberInfo.data.userType == 1
                "
              >
                待审批
              </div> -->
            <!-- <div
                :class="{ item: true, active: current_state == 10 }"
                @click="changeState(10)"
              >
                {{ L["待付款"] }}
              </div> -->
            <div :class="{ item: true, active: current_state == 20 }" @click="changeState(20)">
              {{ L["待发货"] }}
            </div>
            <div :class="{ item: true, active: current_state == 30 }" @click="changeState(30)">
              {{ L["待收货"] }}
            </div>
            <div :class="{
              item: true,
              active: current_state == 40 && evaluate_state != 1,
            }" @click="changeState(40)">
              {{ L["已完成"] }}
            </div>
            <!-- <div :class="{item:true,active:current_state==40&&evaluate_state==1}" @click="changeState(40,1)">{{L['待评价']}}</div> -->
          </div>

          <!--<div class="search_con2 flex_row_between_center">
            <span style="color: #B3B3B5; font-size: 14px;">订单号： </span>
            <div class="search_incon flex_row_start_center">
              <el-input class="search_input"  v-model="memberOrderCode" :placeholder="L['订单号']" clearable @clear="clear">
              </el-input>
              
            </div>
            <span style="color: #B3B3B5; font-size: 14px;  margin-left: 13px;">商品名称：</span>
            <div class="search_incon flex_row_start_center">
              <el-input class=" search_input" v-model="keyword" placeholder="商品名称" clearable @clear="clear">
              </el-input>
            </div>
            <div class="demo-date-picker1">
              <span class="Creationime" style="color: #B3B3B5; font-size: 14px;">下单时间：</span>
              <div class="block">
                <el-date-picker class="tabtime" v-model="orderTime" type="daterange" range-separator="至"
                  start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD" @change="changeTime" />
              </div>
            </div>
            <div class="reset-btn">
              <el-button style="color: white; background-color: #03499e"
                :style="orderTypeKeyword == 4 ? 'margin-top:5px' : ''" @click="getOrderList">搜索</el-button>
              <el-button style="color: white; background-color: #03499e"
                :style="orderTypeKeyword == 4 ? 'margin-top:5px' : ''" @click="resetHandle">重置</el-button>
            </div>
          </div>-->
           <div class="headerSearch4" style="padding-top:25px;    border-top: 1px solid #e8e8f1;">
          <el-form
            ref="ruleFormRef"
            :model="param"
            :inline="true"
            class="demo-form-inline"
          >
            <el-form-item label="订单号" prop="extOrderCode">
              <el-input v-model="param.extOrderCode" style="width: 190px" />
            </el-form-item>
            <el-form-item
              label="商品名称"
              prop="goodsName"
              :style="platform == 2?'margin-left: 10px':''"
            >
              <el-input v-model="param.goodsName" style="width: 190px" />
            </el-form-item>
            <el-form-item label="下单时间" prop="time">
              <el-date-picker
                v-model="param.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                style="width: 230px"
                
              />
            </el-form-item>
           
            <div class="searchBtn3">
              <div class="button" @click="onSubmit(ruleFormRef)">搜索</div>
              <el-button @click="resetForm(ruleFormRef)">重置</el-button>
            </div>
           
          </el-form>
        </div>
        </div>
        <div class="order_title_info flex_row_start_center">
          <div class="time_select">{{ L["订单详情"] }}</div>
          <div class="good_price">{{ L["单价"] }}</div>
          <div class="num">{{ L["数量"] }}</div>
          <!-- <div class="after">{{L['售后维权']}}</div> -->
          <div class="order_price">{{ L["订单金额"] }}</div>
          <div class="state">{{ L["状态"] }}</div>
          <div class="oprate">{{ L["操作"] }}</div>
        </div>

        <!-- <div class="" v-for="(goodItem, index) in orderItem.extMemberOrderDetailList" :key="index">
          sahfjhesogjpwrjgphrwpghwrghergjb
          {{ goodItem.supplierUserName }}
        </div> -->
    

        <div class="order_item" v-for="(orderItem, index) in order_list.data" :key="index">
          <div class="title flex_row_between_center">
            <div class="flex_row_start_center">
              <div>{{ L["下单时间"] }}：{{ orderItem.extMemberOrderResp.placeOrderTime }}</div>
              <!-- memberName -->
              <div class="order_num" @click="goDetail(orderItem.extMemberOrderResp.extOrderCode)">
                {{ L["订单号"] }}: {{ orderItem.extMemberOrderResp.extOrderCode }}
              </div>
              <div class="MeName">
                <div class="item flex_row_start_center" v-for="(goodItem1, index) in orderItem.extMemberOrderDetailList"
                :key="index">
                <div>供应商名称：{{ goodItem1.supplierUserName }}</div>
              </div>
                <!-- {{ orderItem.extMemberOrderResp.memberName }} -->
                  
              </div>

              <div class="store_name" style="max-width: 160px" @click="toStore(orderItem.storeId)">
                <!--<el-row style="width: 120px" >
                    <el-text :truncated="true">{{ orderItem.seller }}</el-text>
                  </el-row>-->
                {{ orderItem.seller }}
              </div>

              <!-- <div class="store_service" @click="toKefu(orderItem)">
                  <img src="@/assets/goods/server.png" alt="" />
                </div> -->
              <!-- <div class="orderPayTyp_eName">
                                {{ orderItem.orderPayTypeName }}
                            </div>
                            <div class="orderPayTyp_eName" v-if="orderItem.paymentWayName">
                                {{ orderItem.paymentWayName }}
                            </div> -->
              <div class="payment_date" v-if="
                orderItem.orderPayType == 2 &&
                orderItem.orderBelongs == 2 &&
                orderItem.arriveDate
              ">
                付款日期：{{ orderItem.arriveDate }}
              </div>
            </div>
            <div v-if="orderItem.orderState != 0">
              <div class="downLoad_contract" @click="clickDownload(orderItem)">
                <!-- orderBelongs 订单所属 1=自营，2=直售 -->
                <el-image style="width: 20px; height: 20px; margin-right: 4px" :src="itemImg" />
                <span>下载销售合同</span>
              </div>
            </div>
          </div>
          <div class="good_info_con flex_row_start_center">
            <div class="good_info flex_column_center_start">
              <!-- {{ JSON.stringify(orderItem.extMemberOrderDetailList) }} -->
              <div class="item flex_row_start_center" v-for="(goodItem, index) in orderItem.extMemberOrderDetailList"
                :key="index">
                <!-- orderItem.goodsDetails -->
                <div class="good flex_row_start_center">
                  <div class="left">
                    <!-- <div class="virtual_tag" v-if="orderItem.isVirtualGoods == 2">
                                            虚拟
                                        </div>
                                        <coverImage :src="goodItem.goodsImage.replace(/\s/g, '%20')"
                                            @click="goGoodsDetail(goodItem.skuCode)" width="100" height="100"
                                            style="border-radius: 6px"></coverImage> -->
                    {{ goodItem.goodsName }}
                  </div>

                  <div class="right">
                    <!-- <div class="good_name overflow_ellipsis_two"
                                            @click="goGoodsDetail(goodItem.skuCode)">
                                            {{ goodItem.goodsNameJoin }}
                                        </div>
                                        <div class="spec overflow_ellipsis">
                                            {{ goodItem.specValues }}
                                        </div> -->
                    <!-- {{ goodItem.supplierUserId }} -->

                    {{ goodItem.marketingUnit }}

                  </div>
                </div>
                <div style="display: flex; align-items: center">
                  <div class="good_price">￥{{ goodItem.goodsPrice }}</div>
                  <div class="num">*下单数量{{ goodItem.goodsNum }}</div>
                  <div class="num" v-if="goodItem.confirmTonnageNum">
                    *修改数量{{ goodItem.confirmTonnageNum }}
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="after flex_row_center_center">--</div> -->
            <div class="order_price flex_row_center_center">
              ￥ {{ orderAmtShowContent(orderItem.extMemberOrderResp.orderShouldSumAmount,
                orderItem.extMemberOrderResp.orderShouldSumAmount) }}
            </div>
            <div class="state flex_column_center_center">
            
             {{
                orderItem.extMemberOrderResp.orderState==20?"待发货":orderItem.extMemberOrderResp.orderState==30?"待收货":orderItem.extMemberOrderResp.orderState==40?"已完成":''
              }}
              <!-- <p style="margin-top: 9px" v-if="memberInfo.data.memberId != orderItem.memberId">
                申请人：{{ orderItem.extMemberOrderResp.receiverName }}
              </p> -->
            </div>
            <div class="oprate flex_column_center_center">
              <!-- 待付款订单可以立即支付 -->
              <div v-if="orderItem.isCurrentPay && orderItem.orderPayType != 1"
                class="action-btn recom flex_row_center_center" @click="goPay(orderItem.paymentNumber)">
                {{ L["立即支付"] }}
              </div>
              <!-- 分期付款订单可以分期支付 -->
              <div v-if="
                orderItem.orderState != 0 &&
                orderItem.orderPayType == 1 &&
                orderItem.stagesStatus == 1
              " class="action-btn recom flex_row_center_center" @click="goPay(orderItem.paymentNumber)">
                {{ fliter(orderItem.paymentNum) }}
              </div>
              <!-- 待付款、待发货订单可以修改地址 -->
              <!-- <div v-if="
                                (orderItem.orderState == 10 || orderItem.orderState == 20) &&
                                orderItem.memberId == memberInfo.data.memberId
                            " class="action-btn flex_row_center_center"
                                @click="editAddress(orderItem.memberOrderCode)">
                                {{ L["修改地址"] }}
                            </div> -->
              <!-- 待收货、已完成订单可以查看物流 -->
              <div v-if="
                (orderItem.orderState == 30 || orderItem.orderState == 40) &&
                orderItem.memberId == memberInfo.data.memberId
              " class="action-btn flex_row_center_center" @click="
                lookLogistics(
                  orderItem.memberOrderCode,
                  orderItem.productLeadLittle
                )
                ">
                {{ L["查看物流"] }}
              </div>
              <!-- 待付款订单可以取消订单 -->
              <div v-if="
                (orderItem.orderState == 10 || orderItem.orderState == 1) &&
                orderItem.memberId == memberInfo.data.memberId
              " class="action-btn flex_row_center_center" @click="showSelectReasonDialog(orderItem)">
                {{ L["取消订单"] }}
              </div>
              <span class="detail" @click="approval_sub(2, orderItem.orderSn)"
                v-if="orderItem.orderState == 1">审核拒绝</span>
              <span class="detail" @click="goDetail(orderItem.extMemberOrderResp.extOrderCode)"
                v-if="orderItem.orderState != 1">{{
                  L["订单详情"] }}</span>
              <span class="detail" @click="open(orderItem.extMemberOrderResp.extOrderCode)"
                v-if="orderItem.extMemberOrderResp.orderState == 30">
                <span>{{
                  L["确认收货"] }}</span>
              </span>
              <span class="detail" @click="goDetails(goodItem.memberOrderCode)"
                v-if="orderItem.orderState == 1">订单详情</span>
              <!-- 待收货订单可以确认收货 -->
              <!--<div v-if="
                orderItem.extMemberOrderResp.orderState == 30 
              " class="action-btn recom flex_row_center_center" @click="confirmReceipt(orderItem)">
                {{ orderItem.isTrue ? "修改收货吨位" : "确认收货" }}
              </div>-->
              <!-- 确认收货的被修改了数量的订单 -->
              <div v-if="orderItem.userInputNum && orderItem.orderState != 40"
                class="action-btn recom flex_row_center_center" @click="confirmReceipt(orderItem)">
                修改收货数量
              </div>
              <!-- 查看订单付款比例 -->
              <div v-if="orderItem.orderPayType == 1" class="action-btn recom flex_row_center_center"
                @click="handleInstallment(orderItem.memberOrderCode)">
                查看付款比例
              </div>
              <!-- <div v-if="orderItem.orderBelongs !== 2" class="action-btn flex_row_center_center"
                                @click="DownloadSalesList(orderItem)">
                                下载收货清单
                            </div> -->
            </div>
          </div>

        </div>
        <!-- 分页 -->
        <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
          :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
          :hide-on-single-page="true" class="flex_row_end_center"></el-pagination>
        <SldCommonEmpty v-if="order_list.data.length == 0" :tip="L['暂无订单～']" totalWidth="934px" />
      </div>
      <!-- 取消理由弹窗 start -->
      <el-dialog :title="L['取消订单理由']" v-model="cancel_order" customClass="select_reason_width" :lock-scroll="false">
        <div class="cancel_list_con">
          <div v-for="(reasonItem, index) in cancel_list.data" :key="index" :class="{
            reason_item: true,
            flex_row_between_center: true,
            active: current_reason_id == reasonItem.reasonId,
          }" @click="selectReason(reasonItem)">
            <span class="reason_text">{{ reasonItem.content }}</span>
            <img :src="current_reason_id == reasonItem.reasonId ? selected : not_select
              " alt />
          </div>
        </div>
        <div class="confirm_cancel_btn" @click="confirmCancel()">
          {{ L["确定"] }}
        </div>
      </el-dialog>
      <!--  取消地址理由弹窗 end  -->
      <!-- 查看物流弹窗 start -->
      <el-dialog :title="L['物流信息']" v-model="show_logistics" customClass="select_reason_width_wuliu"
        :lock-scroll="false">
        <div class="logistics_info_box">
          <!--v-if="order_info.data.orderState == 30 ||order_info.data.orderState == 40" -->
          <div class="logistics_info_nav flex">
            <div :class="{ active: logisticsIndex == index }" v-for="(item, index) in logistics_info.data" :key="index"
              @click="handleLogistics(item, index)">
              物流{{ index + 1 }}
            </div>
          </div>
          <div v-for="(item, index) in logistics_info.data" :key="index">
            <div class="logistics_info flex_row_start_center" v-show="logisticsIndex == index">
              <div class="logistics_info_left flex_row_center_center">
                <!-- <div class="image flex_row_center_center">
                    <img :src='logistics_info.data.goodsImage' alt />
                    <coverImage :src='logistics_info.data.goodsImage' width="64" height="64"></coverImage>
                  </div> -->
                <div class="info">
                  <!-- <p>{{L['送货方式']}}：{{logistics_info.data.type==1?'无需物流':'快递'}}</p> -->
                  <p>{{ L["物流公司"] }}：{{ item.logisticsCompany }}</p>
                  <p>{{ L["物流单号"] }}：{{ item.trackingNumber }}</p>
                  <p>包含商品：{{ item.sendNum }}</p>
                </div>
              </div>
              <div v-if="logisticsLog.data" class="logistics_info_right">
                <div class="right_container">
                  <img v-if="Object.keys(logisticsLog.data).length == 0" :src="express_empty" alt />
                  <p v-if="Object.keys(logisticsLog.data).length == 0">
                    {{ L["暂无物流信息"] }}
                  </p>
                  <el-timeline v-if="
                    logisticsLog.data &&
                    Object.keys(logisticsLog.data).length > 0
                  ">
                    <el-timeline-item v-for="(item, index) in logisticsLog.data" :key="index"
                      :timestamp="item.acceptStation">{{ item.acceptTime }}</el-timeline-item>
                  </el-timeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <!--  查看物流弹窗 end  -->
      <!-- 查看分期付款弹窗 start -->
      <el-dialog title="分期付款信息" v-model="show_installment" customClass="select_reason_width" :lock-scroll="false">
        <div class="ment-box">
          <div class="item0">
            <div class="title">付款次数</div>
            <div class="item0-num">{{ installmentObj.data?.length }} 次</div>
          </div>
          <div class="item1" v-for="(item, index) in installmentObj.data" :key="index">
            <div class="lt">
              <div class="title">付款状态</div>
              <div v-if="item.stagesState == 10">待付款</div>
              <div v-if="item.stagesState == 20">待发货</div>
              <div v-if="item.stagesState == 30">待收货</div>
              <div v-if="item.stagesState == 40">已完成</div>
              <div v-if="item.stagesState == 0">
                质保时间
                <div>{{ item.guaranteeTime }}</div>
              </div>
              <div>
                <span>付款状态：</span>
                <span>{{ item.paymentSate ? "已付款" : "未付款" }}</span>
              </div>
            </div>
            <div class="md">
              <div>付款时间</div>
              <div>{{ item.paymentTime }}</div>
              <div v-if="item.stagesCode">
                交易流水号：{{ item.paymentSate ? item.stagesCode : "暂无" }}
              </div>
            </div>
            <div class="rt">
              <div>付款比例（百分比）</div>
              <div>{{ item.stagesRatio }}%</div>
              <div>付款金额：￥{{ item.stagesAmount }}</div>
            </div>
          </div>
        </div>
      </el-dialog>
      <!--  查看分期付款弹窗 end  -->
      <!-- 地址选择弹窗 start -->
      <el-dialog :title="L['更换地址']" v-model="show_select_address" customClass="select_address_width"
        :lock-scroll="false">
        <div class="out_stock_dialog address_con">
          <div v-for="(item, index) in address_list.data" :key="index" @click="selectAddress(index)" :class="{
            address_item: true,
            flex_column_start_start: true,
            select: current_address_index == index,
          }">
            <span>{{ L["收货人"] }}：{{ item.memberName }}</span>
            <span>{{ L["联系方式"] }}：{{ item.telMobile }}</span>
            <span class="address_text">
              <div>{{ L["收货地址"] }}：</div>
              <div>{{ item.addressAll }} {{ item.detailAddress }}</div>
            </span>
            <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
          </div>
        </div>
        <div class="btn_con flex_row_center_center">
          <div class="confirm_cancel_btn" @click="confirmChangeAddress()">
            {{ L["提交修改"] }}
          </div>
        </div>
      </el-dialog>
      <!-- 地址选择弹窗 end -->
      <!-- 审批选择弹窗 start-->
      <el-dialog v-model="diaflag" title="审批" width="560px" @close="reason = ''">
        <div class="aftersales_box">
          <div class="approval_box">
            <p class="approval_tit">审批：</p>
            <div class="approval_con">
              <div class="approval_con_1" @click="approval_info(1)" :class="{ approval_con_one: approval_flag }">
                通过
              </div>
              <div class="approval_con_2" @click="approval_info(2)" :class="{ approval_con_one: !approval_flag }">
                拒绝
              </div>
            </div>
          </div>
          <div class="approval_box" style="margin-top: 20px" v-if="!approval_flag">
            <p class="approval_tit">拒绝原因：</p>
            <textarea name="" id="" placeholder="请输入拒绝原因" v-model="reason" maxlength="100"></textarea>
          </div>
        </div>
        <template #footer>
          <div style="width: 78px"></div>
          <span class="dialog-footer dia_btn">
            <el-button type="primary" @click="diasumber"
              style="background-color: #0e3177; border-color: #0e3177">确定</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 审批选择弹窗 end-->
    </div>
    <div class="container">
      <!-- 确认收货弹窗 start-->
      <el-dialog v-model="dialogFormVisible" :title="orderProductObj.isTrue ? '确认收货' : '修改收货吨位'" width="40%">
        <el-form label-position="left">
          <el-form-item :label="item.goodsNameJoin.length > 36
            ? item.goodsNameJoin.slice(0, 36) + '.....'
            : item.goodsNameJoin.slice(0, 36)
            " :title="item.goodsNameJoin" v-for="(item, index) in orderProductList.data" :key="index">
            <el-input-number v-model="item.confirmTonnageVal" :controls="false" :precision="4" />
            <span class="unit">{{ item.marketingUnit }}</span>
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="bootom-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <div class="button" @click="confirmNum">确定</div>
          </div>
        </template>
      </el-dialog>
      <!--<el-dialog
          v-model="dialogVisible"
          :show-close="false"
          top="10%"
          width="30%"
        >
          <div>
            
            <div class="my-header">
              <div class="modalTitle">
                <img src="../../../assets/supplier_warning.png" alt="" /><span
                  >提示</span
                >
              </div>
              <div style="font-size: 24px; color: rgb(163 158 158)">×</div>
               <div class="result">确定收货吗</div>
              <div></div>
            </div>
          </div>
        </el-dialog>-->

      <!--<el-dialog v-model="dialogFormVisible" >
          <template #header="{  titleClass }">
            <div class="my-header">
              <h4  :class="titleClass">11111</h4>
              
            </div>
          </template>
          This is dialog content.
        </el-dialog>-->
      <!-- 确认收货弹窗 end-->
    </div>
  </div>
</template>
<script>
  import { reactive, getCurrentInstance, ref, onMounted, computed } from "vue";
  import {
    ElInput,
    ElMessage,
    ElMessageBox,
    ElTimeline,
    ElTimelineItem,
  } from "element-plus";
  import { platform } from "@/utils/config";
  // import { Search } from '@element-plus/icons-vue'
  import { useRouter, useRoute } from "vue-router";
  import { useStore } from "vuex";
  import { apiUrl } from "../../../utils/config";
  import axios from "axios";
  import SldCommonEmpty from "../../../components/SldCommonEmpty";
  import MemberTitle from "../../../components/MemberTitle";
  import download from "downloadjs";
  import { ElButton, ElDialog } from "element-plus";
 
  export default {
    name: "order-list",
    components: {
      ElInput,
      ElTimeline,
      ElTimelineItem,
      SldCommonEmpty,
      MemberTitle,
    },
    setup() {
      // const balance = require("../../assets/buy/balance.png");
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const memberInfo = reactive({ data: store.state.memberInfo });
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const current_state = ref(""); //当前列表订单状态
      const evaluate_state = ref(0); //是否是待评价
      const order_list = reactive({ data: [] });
      const orderSn = ref(""); //订单id
      let orderProductObj = reactive({});
      let orderProductList = reactive({ data: [] }); //确认收货展示数据
      const cancel_order = ref(false); //是否显示取消订单弹窗
      const dialogFormVisible = ref(false); //是否显示取消确认订单弹窗
      const itemImg = require("../../../assets/order/item.png");
      const not_select = require("../../../assets/order/not_select.png");
      const selected = require("../../../assets/order/selected.png");
      const express_empty = require("../../../assets/order/express_empty.png");
      const invoice_selected = require("../../../assets/buy/invoice_selected.png");
      const cancel_list = reactive({ data: [] });
      const current_reason_id = ref(); //记录当前已选择理由id
      const current_reason_content = ref(); //记录当前已选择理由内容
      const order_sn = ref(); //记录当前操作的订单号
      const parentOrderSn = ref();
      const show_logistics = ref(false);
      const show_installment = ref(false);
      const logistics_info = reactive({ data: {} });
      const show_select_address = ref(false); //是否显示选择地址弹窗
      const address_list = reactive({ data: [] }); //地址列表
      const current_address_index = ref(0); //记录当前选择的地址
      const selectTime = ref("");
      const isInstallment = ref(false); //是否是分期
      const stagesStatus = ref(""); //选择订单状态
      const orderTypeKeyword = ref(""); //选择订单类型
      const orderPaymentTypeKeyword = ref(""); //选择订单付款类型
      const installmentName = ref("请选择订单状态");
      const orderTypeName = ref("请选择订单类型");
      const paymentTypeName = ref("请选择订单付款方式类型");
      const selectList = reactive({ data: [] });
      const logisticsCompany = ref(""); //物流公司
      const logisticsNumber = ref(""); //物流单号
      const mobilePhoneNumber = ref(""); //物流收货人电话
      const logisticsIndex = ref(0); //物流信息下标
      const logisticsLog = reactive({ data: {} }); //物流进度
      const ruleFormRef = ref();
      
      const orderTime = ref([]); //订单下单时间
      const paymentTime = ref([]); //三方订单货到付款时间
      const installmentObj = reactive({ data: [] }); //分期付款信息
      const diaflag = ref(false); //显示审批框
      const approval_flag = ref(true); //审批拒绝通过切换
      const reason = ref(""); //审批拒绝理由
      // 分页
      const pageData = reactive({
        current: 1,
        pageSize: 10,
        total: 0,
      });
      const param = reactive({
      memberId: store.state.memberInfo.memberId,
      extOrderCode: "",
      state: "",
      goodsName:"",
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
  
      const orderAmtShowContent = (oldAmt, newAmt) => {
        if (oldAmt == newAmt) {
          return oldAmt;
        }
        return oldAmt + '/' + newAmt
      }
  
      //下载
      const clickDownload = (orderContractUrl) => {
        console.log('orderContractUrl', orderContractUrl)
        window.open(orderContractUrl.extMemberOrderResp.orderContractUrl);
       
      };
      const DownloadSalesList = (e) => {
        proxy
          .$get("api/shopp/member/order/consignmentSheet", {
            orderCode: e.memberOrderCode,
            flag: platform,
          })
          .then((res) => {
            //原接口：/v3/business/front/contract/getOrderContract
            if (res.code == 200) {
              // download(res.msg);
              window.open(res.data);
              // ElMessage.success("下载成功")
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      
      
      const keyword = ref("");
      const memberOrderCode = ref("");
      //去商品详情页
      const goGoodsDetail = (productId) => {
        proxy.$goGoodsDetail(productId);
      };
      //去店铺详情
      const toStore = (storeId) => {
        if (storeId == 0) {
          return;
        }
        proxy.$goStoreDetail(storeId);
      };
      
      
      //切换搜索时间
      const changeTime = (e) => {
        console.log(e);
        // const [placeOrderTimeStart, placeOrderTimeEnd] = e;
        // if(!e) { router.go(0) }
        // isInstallment.value = false;
        resetPageData();
        getOrderList();
      };
      //搜索订单状态
      const installmentHandle = (e) => {
        if (e == 0 || e == 1 || e == 2) {
          isInstallment.value = true;
        }
        stagesStatus.value = e;
        getOrderList();
      };
      //搜索订单类型
      const orderTypeHandle = (e) => {
        orderTypeKeyword.value = e;
        getOrderList();
      };
      //搜索订单类型
      const orderPaymentTypeHandle = (e) => {
        orderPaymentTypeKeyword.value = e;
        getOrderList();
      };
      //重置搜索页面数据
      const resetPageData = () => {
        pageData.current = 1;
        pageData.total = 0;
      };
      //改变订单状态
      const changeState = (orderState, evaluateState) => {
        if (evaluateState) {
          evaluate_state.value = evaluateState;
        } else {
          evaluate_state.value = "";
        }
        current_state.value = orderState;
        pageData.current = 1;
        router.push({
          path: "/member/order/supplierOrders",
          query: {
            orderState,
            evaluateState,
          },
        });
        getOrderList();
      };
      //获取订单列表
      const getOrderList = () => {
        console.log(11,'搜索');
        
        // return
        let params = {};
        if (evaluate_state.value == 1) {
          params.evaluateState = 1;
        }
        if (current_state.value) {
          params.orderState = current_state.value;
        }
        param.pageNum = pageData.current;
        param.pageSize = pageData.pageSize;
        if (orderSn.value) {
          params.orderSn = orderSn.value;
        }
        //param.time = selectTime.value;
        // param.orderStatusKeyword =
        //   isInstallment.value || (!isInstallment.value && selectTime.value)
        //     ? stagesStatus.value
        //     : "";
        //params.orderStatusKeyword = stagesStatus.value;
        //params.orderTypeKeyword = orderTypeKeyword.value;
        //params.paymentWay = orderPaymentTypeKeyword.value;
        if (memberOrderCode.value) {
          params.extOrderCode = memberOrderCode.value;
        }
        if (keyword.value) {
          params.goodsName = keyword.value;
        }
        // debugger
        // if (orderTime.value && orderTime.value.length != 0) {
        //   params.placeOrderTime= orderTime.value[0];
        //   params.placeOrderTime = orderTime.value[1];
        // }
        if(orderTime.value&&orderTime.value.length!=0){
          params.startDate=orderTime.value[0]
          params.endDate=orderTime.value[1]
        }
        //if (paymentTime.value && paymentTime.value.length != 0) {
        //  param.arriveDateStart = paymentTime.value[0];
        //  param.arriveDateEnd = paymentTime.value[1];
        //}
        const paramObj = { ...param ,...params};
        proxy
          .$get("api/shopp/extMemberOrder/queryPage", paramObj) //原接口：/v3/business/front/orderInfo/list
        //   api/shopp/extMemberOrder/queryPage  api/shopp/member/order/page
          .then((res) => {
            if (res.code == 200) {
              for (let i = 0; i < res.data.records.length; i++) {
                for (
                  let j = 0;
                  j < res.data.records[i].extMemberOrderDetailList.length;
                  j++
                ) {
                  if (res.data.records[i].extMemberOrderDetailList[j].firstCategoryId == 12) {
                    res.data.records[i].isTrue = true;
                    break;
                  }
                }
              }
              order_list.data = res.data.records;
              //console.log(11111);
              //console.log(order_list.data);
              pageData.total = res.data.total;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
     
      const handleInstallment = (e) => {
        let param = {};
        param.memberOrderCode = e;
        proxy
          .$get("api/shopp/member/order/stages/list", param)
          .then((res) => {
            //原接口：v3/business/front/order/getInstallment
            if (res.code == 200) {
              installmentObj.data = res.data;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
        show_installment.value = true;
      };
      //确认收货
      const confirmReceipt = (e) => {
        let arr = [];
        let isDz = false;
        // orderProductList.data = [];
        for (let i = 0; i < e.goodsDetails.length; i++) {
          if (e.goodsDetails[i].firstCategoryId == 12) {
            isDz = true;
            e.goodsDetails[i].confirmTonnageNum = e.goodsDetails[i]
              .confirmTonnageNum
              ? e.goodsDetails[i].confirmTonnageNum
              : e.goodsDetails[i].goodsNum;
            e.goodsDetails[i].confirmTonnageVal =
              e.goodsDetails[i].confirmTonnageNum;
            arr.push(e.goodsDetails[i]);
          }
          if (isDz && i == e.goodsDetails.length - 1) {
            orderProductList.data = arr;
            orderProductObj = e;
            return (dialogFormVisible.value = true);
          }
        }
        let memberOrderCode = e.memberOrderCode;
        console.log(7989);
        ElMessageBox.confirm(L["确认收货?"], L["提示"], {
          confirmButtonText: L["确定"],
          cancelButtonText: L["取消"],
          type: "warning",
          center: true,
        })
          .then(() => {
            proxy
              .$post(
                "api/shopp/member/order/confirm/receipt",
                { memberOrderCode },
                "json"
              )
              .then((res) => {
                //原接口：v3/business/front/orderOperate/receive
                if (res.code == 200) {
                  ElMessage.success(L["确认收货成功"]);
                  // getOrderList();
                  changeState(40);
                } else {
                  ElMessage(res.msg);
                }
              })
              .catch(() => {
                //异常处理
              });
          })
          .catch(() => {});
      };
      
      //切换地址
      
      //详情
      const goDetail = (extOrderCode) => {
        console.log(extOrderCode,676);
      
        
        router.push({
          path: "/member/order/detailspage",
          query: {
            extOrderCode: extOrderCode,
          },
        });
      };
      //确认收货按钮
      const open = (extMemberOrderCode) => {
          
          ElMessageBox.confirm(
        '确定收货吗?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            // center: true
        }
    ).then(() => {
        proxy.$get("api/shopp/extMemberOrder/extPurchaseOrderComplete", {
            extMemberOrderCode: extMemberOrderCode,
            flag: platform,
        }).then((res) => {
            if (res.code == 200) {
                ElMessage({
                    type: 'success',
                    message: '确认成功',
                });
                getOrderList();
                // changeState(40);
            } else {
                ElMessage(res.msg);
            }
        }).catch(() => {
            // 异常处理
        });
    }).catch(() => {
        ElMessage({
            type: 'info',
            message: '取消成功',
        });
    });
        }
    
const receiptconfirmed=(extMemberOrderCode)=>{
  // confirmNum()？
  //p
         
}
  
      //审核
      const goDetails = (orderSn) => {
        router.push({
          path: "/member/order/orderReview",
          query: {
            orderSn: orderSn,
            type: 2,
          },
        });
      };
      //向前翻页
      const handlePrevCilickChange = (e) => {
        pageData.current = e;
        getOrderList();
      };
      //向后翻页
      const handleNextCilickChange = () => {
        pageData.current++;
        getOrderList();
      };
      //页数改变
      const handleCurrentChange = (current) => {
        pageData.current = current;
        getOrderList();
      };
  
      // 物流信息切换
      const handleLogistics = (e, i) => {
        logisticsIndex.value = i;
        logisticsCompany.value = e.logisticsCompany;
        logisticsNumber.value = e.trackingNumber;
        mobilePhoneNumber.value = e.mobilePhoneNumber;
        logisticsTraces();
      };
  
      onMounted(() => {
        if (memberInfo.data.isLookOrder == 0) {
          ElMessage.warning("暂无权限访问");
          router.push("/member/index");
          return;
        }
        if (route.query.orderState) {
          current_state.value = route.query.orderState;
        } else {
          current_state.value = "";
        }
        if (route.query.evaluateState) {
          evaluate_state.value = route.query.evaluateState;
        } else {
          evaluate_state.value = "";
        }
       
        getOrderList();
        
        
        
      });
  
      //清空搜索订单
      const clear = () => {
        orderSn.value = "";
        getOrderList();
      };
  
      const toKefu = (item) => {
        let chatInfo = {
          storeId: item.storeId,
          vendorAvatar: item.storeLogo,
          storeName: item.storeName,
          source: "从订单列表进入",
        };
        store.commit("saveChatBaseInfo", chatInfo);
        let newWin = router.resolve({
          path: "/service",
          query: {
            vid: item.storeId,
          },
        });
        window.open(newWin.href, "_blank");
      };
      const onSubmit = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          console.log(1111);
          if(formEl.model.time){
            param.pageNum=1
            param.startDate = formEl.model.time[0];
            param.startDate = formEl.model.time[1];
            //const paramss = { ...param };
          }
          param.time = "";

          //param.time = formEl.model.time;
          
          getOrderList();
        } else {
          console.log("error submit!", fields);
        }
      });
    };
    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
      param.pageNum = 1;
      param.time = "";
      param.startDate = "";
      param.startDate = "";
      getOrderList();

      //param.resetFields()
      //param.memberPlanCode= "",
      // param. source= "",
      // param. prayName= "",
      // param. startDate= "",
      // param. endDate= "",
      //  param.resource= "",
      //  param.state= "",
      //getOrderList()
    };
      router.beforeEach((to, from, next) => {
        if (to.query.orderState) {
          current_state.value = to.query.orderState;
        } else {
          current_state.value = "";
        }
        if (to.query.evaluateState) {
          evaluate_state.value = to.query.evaluateState;
        } else {
          evaluate_state.value = "";
        }
        if (to.path == "/member/order/list") {
          getOrderList();
        }
        next();
      });
      return {
        open,
        receiptconfirmed,
        current_state,
        changeState,
        evaluate_state,
        getOrderList,
        order_list,
        orderSn,
        
        
        orderProductList,
        orderProductObj,
        cancel_order,
        dialogFormVisible,
        itemImg,
        not_select,
        selected,
        cancel_list,
        current_reason_id,
        current_reason_content,
        express_empty,
        logisticsIndex,
        logisticsLog,
        
        handleLogistics,
       
        
       
        
        
        show_logistics,
        show_installment,
        logistics_info,
        confirmReceipt,
        handleInstallment,
        show_select_address,
        address_list,
        invoice_selected,
        current_address_index,
       
      
        
        goDetail,
        goDetails,
        L,
        goGoodsDetail,
        toStore,
        handlePrevCilickChange,
        handleNextCilickChange,
        handleCurrentChange,
        pageData,
        clear,
        toKefu,
        stagesStatus,
        selectTime,
        isInstallment,
        orderTime,
        paymentTime,
        installmentName,
        orderTypeName,
        orderTypeKeyword,
        selectList,
       
        installmentObj,
        
       
        changeTime,
        installmentHandle,
        orderTypeHandle,
        resetPageData,
        memberOrderCode,
        keyword,
        diaflag,
        approval_flag,
        
        
        reason,
        memberInfo,
        clickDownload,
        
        DownloadSalesList,
        paymentTypeName,
       
        orderPaymentTypeKeyword,
        orderPaymentTypeHandle,
        orderAmtShowContent,
        param,
        onSubmit,
         ruleFormRef,
         resetForm
      };
    },
  };
  </script>
<style lang="scss">
@import "../../../style/orderList.scss";

.el-pager li.active {
  color: $colorMain;
  cursor: default;
}

.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  .el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }

    .el-dialog__headerbtn {
      top: auto;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }

  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }

  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }

  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }

  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }

  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }

  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}

.container {
  .bootom-footer {
    /*text-align: right;
          display: initial;*/
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .bootom-footer button:first-child {
    margin-right: 10px;
  }

  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button {
  background: #0e3177;
  width: 70px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}

.el-button--primary {
  --el-button-background-color: #0e3177;
  --el-button-border-color: #0e3177;
  --el-button-hover-color: #0e3177;
  --el-button-background-color: #0e3177;
  --el-button-border-color: #0e3177;
  --el-button-hover-color: #0e3177;
}

.el-message-box--center .el-message-box__header {
  padding-top: 15px;
}

.headerSearch4{
  .el-range-separator{
    width: 10%;
  }
}
</style>